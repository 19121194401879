/* components/HomeMenu/HomeMenu.css */

.home-menu {
    display: flex;
    flex-direction: column;
    height: calc(100 * var(--vh));
    width: 100%;
    align-items: center;
    justify-content: space-between; /* to align items to the top */
}

.background-video {
    width: calc(100% + 40px);
    margin-left: -18px;
    height: 32vh;
    overflow: hidden;
    margin-right: -18px;
    position: relative;
}

.background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: none; /* Ensuring the video scales */
    min-width: 100%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
}





.menu-title {
    max-width: 100%;
    height: auto;
    /* flex: 1; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
}


.menu-title img {
    width: 140px;
}

.menu-title p {
    font-size: 16px;
}

.date {
    opacity: .5;
    font-family: 'Inconsolata', monospace;
    font-weight: 600;
    margin-top: -8px;
    margin-bottom: 0px;
    

}

.menu-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-bottom: 40px;
}

.menu-buttons button {
    height: 56px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);  /* Default button color */
    color: #fff;
    border: solid 1px #fff;
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    font-weight:800;
    margin: 4px 0;  /* Updated margin */
    padding: 0;  /* Removed padding since we're now controlling height directly */
    transition: background-color 0.3s;  /* Smoothens the hover effect */
}

.menu-buttons button:hover {
   opacity: 0.9;
}

.menu-buttons button:first-child {
    background-color: #fff;  /* Color for the "Start" button */
    color: #000;  /* Text color for the "Start" button */
}

.menu-buttons button:first-child:hover {
    opacity: 0.9;
}


@media screen and (min-width: 1024px) {


    .home-menu {
        width: 100%; /* Ensure it takes up the full width */
        justify-content: center; /* This will center the children vertically */
        height: 100%;
        padding-top: 0px;
        gap: 24px;

    }

    .menu-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;  /* Horizontally center the buttons */
    }

    .menu-buttons button {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}