/* components/GameScreen/Navbar/Navbar.css */

.navbar {
    display: flex;
    flex-direction: row;  /* Explicitly set to row */
    justify-content: space-between;
    align-items: center; 
    width: 100%;
    height: 56px;
    padding-top: 8px;
    /* border-bottom: solid 2px #EBEBEB; */
}

.right-icons {
    display: flex;
    gap: 8px; 
    color: #383838;
}

.close-btn {
    cursor: pointer;  /* To indicate it's clickable */
    color: #383838;
    height: 24px;
    /* Additional styles for the close button can be added here if needed */
}
