@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');

html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #000;
}


.App {
  height: calc(100 * var(--vh));
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);  */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  color: #fff;
  font-family: 'Inconsolata', monospace;
  font-size: 24px;
  font-weight: bold;
}

h2 {
  color: #fff;
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  font-weight: 600;
}

h3 {
  color: #fff;
  opacity: .8;
  font-family: 'Inconsolata', monospace;
  font-size: 11px;
  font-weight: 600;
}

p {
  color: #fff;
  opacity: .9;;
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  line-height: 20px;
}

a {
  color: #fff;
  opacity: .9;;
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;

}

ul li {
  color: #fff;
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  font-weight: 400;
  opacity: .8;
  line-height: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1024px) {
  .App {
      max-height: 800px;
      max-width: 400px;
      margin: auto;
      display: flex;          /* Change to flex */
      justify-content: center; /* Horizontally center children */
      align-items: center;     /* Vertically center children */
  }

}


