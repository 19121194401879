/* 
components/GameScreen/GameScreen.css 
*/

/* ========== GLOBAL STYLES ========== */

.game-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100 * var(--vh));
    gap: 20px;
}

.question-content-container {
    width: 100%;
}

/* ========== MOVIE STILL ========== */

.movie-still-container {
    width: 100vw;
    margin-left: calc(-50vw + 50%);  /* This will center the container on the screen */
    overflow: hidden;  /* Hide any overflow content */
}

.movie-still {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
}

.movie-still img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

/* ========== CIRCLE INDICATORS ========== */

.circle-indicators-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 24px;
}

.prev-arrow, .next-arrow {
    flex-shrink: 0; /* Prevent arrows from shrinking */
    height: 56px;
    width: 33.33%;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* .prev-arrow:hover, .next-arrow:hover {
    background-color: #F7F7F7;
} */

.circle-indicators {
    display: flex;
    gap: 16px;
    flex-grow: 1; /* Allow circle indicators to take up remaining space */
    justify-content: center;
    align-items: center;
    padding: 0 16px; /* Provide some space between the arrows and the circles */
}


.circle-indicators div {
    width: 32px;
    height: 32px;
    transition: width 140ms ease-in-out, height 140ms ease-in-out;
    transform-origin: center center;
  
}

.circle-indicators div.answered-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Aligns the emoji in the center vertically */
    position: relative;
}

.emoji {
    position: absolute;
    transform: translate(-50%, -50%) scale(0.5); /* Combining the two transforms */
    z-index: 1;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.circle {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #000;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    /* transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; */
}

.circle.answered {
    background-color: rgba(255, 255, 255, 0.4);
}

.circle.submitted {
    display: none;
}

.circle.current {
    box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
}


/*======== New Animations ++++++ */

@keyframes scaleDown {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    70% {
        transform: scale(1.1);  /* Scaled up a bit more than its original size */
        opacity: 1;
    }
    100% {
        transform: scale(1);  /* Return to its original size */
        opacity: 1;
    }
}



/* Adjusting the circle and emoji styles */

.circle-indicators .circle {
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.emoji {
    display: inline-block;
    font-size: 32px;
    opacity: 0; /* start with hidden emoji */
    /* transform: scale(0);  */
    transition: transform 0.5s ease-in-out, opacity 0.25s ease-in-out;
}


/* Adjusting the circle and emoji styles */
.animate-circle {
    animation: scaleDown 0.5s forwards;
}

.animate-emoji {
    animation: scaleUp 0.5s forwards;
    
}

/* Sequential animations for circle indicators and emojis */

/* Delay for first circle */
.circle-indicators > div:nth-child(1) > .circle {
    animation-delay: 0s;
}

/* Delay for first emoji */
.circle-indicators > div:nth-child(1) > .emoji {
    animation-delay: 0.15s;
}

/* Delay for second circle */
.circle-indicators > div:nth-child(2) > .circle {
    animation-delay: 0.25s;
}

/* Delay for second emoji */
.circle-indicators > div:nth-child(2) > .emoji {
    animation-delay: 0.4s;
}

/* Delay for third circle */
.circle-indicators > div:nth-child(3) > .circle {
    animation-delay: 0.5s;
}

/* Delay for third emoji */
.circle-indicators > div:nth-child(3) > .emoji {
    animation-delay: 0.65s;
}





/* ========== TITLE CHOICES ========== */

.title-choices {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: 8px;
    padding-top: 24px;
}

.title-choice-button {
    height: 56px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: solid 1px #fff;
    font-size: 16px;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    transition: background-color 0.3s;
}

/* .title-choice-button:hover, .title-choice-button.selected:hover {
    background-color: #5a5851;
    color: #fff;
} */

.title-choice-button.selected {
    background-color: #fff;
    color: #000;
}

.title-choice-button.correct {
    background-color: #40D97D;
    color: #000;
    border: none;
}

.title-choice-button.incorrect {
    background-color: #fff;
    color: #000;
}

/* ========== ACTION BAR ========== */

.action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    gap: 8px;
    padding-bottom: 24px;
}

/* .prev-arrow, .next-arrow {
    height: 56px;
    width: 33.33%;
    background: none;
    border: none;
    border-radius: 4px;
    color: #979797;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    display: flex;
    justify-content: center;
}

.prev-arrow:hover, .next-arrow:hover {
    background-color: #F7F7F7;
} */

.submit-btn {
    height: 56px;
    width: 40%;
    background-color: #fff;
    color: #000;
    border: none;
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.2;
    transition: background-color 0.3s;
}

.submit-btn.active {
    background-color: #F7E08E;
    color: #000;
    opacity: 1.0;
}

.submit-btn.active:hover {
    opacity: 0.9;
}

.share-btn {
    color: 000;
    background-color: #fff;
    opacity: 1.0;
}

/* ========== MEDIA QUERIES ========== */

@media screen and (min-width: 1024px) {
    .game-screen {
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .question-content-container,
    .movie-still-container,
    .title-choices,
    .action-bar {
        max-width: 400px;
    }


    .movie-still-container {
        margin: 0 auto;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
    }
   
}

@keyframes jump {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

.emoji.jump {
    animation: jump 0.5s;
}
